import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import Poem from "../../../../../components/poem"
import Columns from "../../../../../components/columns"
import Column from "../../../../../components/column"
import Text from "../../../../../components/text"
import Heading from "../../../../../components/heading"
import Paragraph from "../../../../../components/paragraph"
import PoemHaelfteDesLebens from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/poem-haelfte-des-lebens"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const taskProps = {
    courseId: "haelfte-des-lebens",
    chapterId: "01-zwei-haelften",
    taskId: "spiel-mit-versgrenzen",
  }
  const answer = getAnswer(taskProps)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/haelfte-des-lebens/01-zwei-haelften/spiel-mit-versgrenzen/info-1"
        />
      }
    >
      <Seo title="Versgrenzen setzen" />
      <Stack>
        <Heading as="h2" level={2}>
          Versgrenzen setzen
        </Heading>
        <Columns>
          <Column>
            <Stack space={5}>
              <Text sans bold>
                Deine Antwort
              </Text>
              <Poem size={[3, 3, 4]}>
                <div dangerouslySetInnerHTML={{ __html: answer }}></div>
              </Poem>
            </Stack>
          </Column>
          <Column>
            <Stack space={5}>
              <Text sans bold>
                Lösung
              </Text>
              <PoemHaelfteDesLebens />
            </Stack>
          </Column>
        </Columns>
        <Paragraph>
          Hölderlin hat sich bei der Gestaltung der Verse nicht an Satzgrenzen
          gehalten und bewusst mit Zeilensprüngen, sogenannten Enjambements,
          gespielt: Über die Versgrenzen hinweg werden so Sinneinheiten
          miteinander verbunden oder aber auch voneinander getrennt. So
          entstehen ungewöhnliche Pausen und Spannungsbögen.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
